<!-- THIS IS BASED OFF OF AN EXAMPLE ON CODEPEN -->
<!-- https://codepen.io/koca/pen/xJqYpR -->
<template id="image-compare-component">
    <div class='ic_wrapper' ref="wrapper">
<!--        Ratio: {{image1Ratio}} Width: {{width}} Height: {{ parseFloat(width) * image1Ratio}}-->

        <div class="ic_compare-wrapper"  v-if="show">

            <div class="ic_compare">
                <div class="ic_compare__content" :style="{'width': width, height: parseFloat(width) * image1Ratio + 15 + 'px'}">
                    <img
                            :src="after"
                            alt="Before"
                            style="width: 100%"
                    >
                    <!--                <slot name="first"></slot>-->
                </div>

                <resize-observer @notify="handleResize"></resize-observer>
                <div
                        class="ic_handle-wrap"
                        :style="{left:`calc(${compareWidth + '%'} - var(--handle-line-width) / 2`}"
                >
                    <div class="ic_handle">
                        <svg
                                class="handle__arrow handle__arrow--l feather feather-chevron-left"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="square"
                                stroke-linejoin="square"
                        ><polyline points="15 18 9 12 15 6"/></svg>
                        <svg
                                class="handle__arrow handle__arrow--r feather feather-chevron-right"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                        ><polyline points="9 18 15 12 9 6"/></svg>
                    </div>
                    <span class="ic_handle-line"></span>
                </div>


                <div class="ic_compare-overlay " :style="{width:`calc(${compareWidth + '%'})`}">

                    <div class="ic_compare-overlay__content" :style="{ 'width': width}">
                        <img

                                :src="before"
                                alt="After"
                        >
                    </div>
                </div>



            </div>
            <input
                    type="range"
                    min="0"
                    max="100"
                    :step="step"
                    class="ic_compare__range"
                    :value="compareWidth"
                    @input="handleInput"
                    tabindex="-1"
            >

        </div>
    </div>

</template>

<script>

    import 'vue-resize/dist/vue-resize.css'
    import { ResizeObserver } from 'vue-resize'
    export default {
        name: "ImageCompare",
        components: {
            ResizeObserver
        },
        props:{
            value: { default: 50 },
            step: { default: '.1' },
            before: {default: ''},
            after: {default: ''},
            show: {default: true}
        },
        data(){
            return {
                width: null,
                compareWidth: this.value,
                image1Ratio: 0,
                mobile: false
            }
        },
        template: '#image-compare-component',
        watch:{
            value(){
                this.compareWidth= this.value
            }
        },
        mounted(){
            this.width = this.getContainerWidth();

            this.getImageMeta(this.before, 'image1');
            this.getImageMeta(this.after, 'image2');

            this.handleResize();
        },
        methods:{
            getImageMeta(url, imageNum){
                var img = new Image();
                const that = this;
                img.addEventListener("load", function(){
                    that[imageNum + 'Height'] = img.naturalHeight;
                    that[imageNum + 'Width'] = img.naturalWidth;
                    that[imageNum + 'Ratio'] = img.naturalHeight / img.naturalWidth
                    that.width = that.getContainerWidth();
                });
                img.src = url;
                setTimeout(() => {
                    // console.log('IMAGE STUFF', this.image1Height)
                    // console.log('IMAGE STUFF', this.image1Width)
                    // console.log('IMAGE STUFF', this.image1Ratio)
                    this.width = this.getContainerWidth();
                }, 1)
            },
            handleInput(e){
                this.compareWidth = e.target.value
                this.$emit('input', e.target.value)
            },
            handleResize(){
                const w = this.getContainerWidth();
                if(w === this.width)
                    return;
                this.width = w
                // console.log(this.width)
            },
            getContainerWidth(){
                // console.log('WRAPPER', this.$refs.wrapper)
                // console.log('WRAPPER WIDTH', this.$refs.wrapper.offsetWidth)

                return this.$refs.wrapper.offsetWidth + 'px'
            },
        }
    }
</script>

<style lang="scss">
    :root{
        --handle-bg: #3671FF;
        --handle-width: 30px;
        --handle-height: 30px;
        --handle-chevron-size: 20px;

        --handle-line-bg: #90B2FC;
        --handle-line-width: 4px;
        --handle-line-height: 100%;

        --z-index-handle: 9995;
        --z-index-handle-line: 9994;
        --z-index-range-input: 9996;
    }
    .ic_wrapper {
        margin-top: -15px;
        /*padding-bottom: 15px;*/
    }
    .ic_compare-wrapper{
        float: right;
        position: relative;
        box-shadow: 0 3px 1px -4px rgba(0,0,0,.9), 0 1px 5px 0 rgba(0,0,0,.12);
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;

        .ic_compare,
        .ic_compare__content{
            position: relative;
            height: 100%;
        }

        .ic_compare-overlay{
            position: absolute;
            overflow:hidden;
            height: 100%;
            top:0;
        }
        .ic_compare-overlay__content{
            position:relative;
            height: 100%;
            width: 100%;
        }

        .ic_handle__arrow{
            position: absolute;
            width: var(--handle-chevron-size);
        }
        .ic_handle__arrow--l{
            position: relative;
            left: 2px;
        }
        .ic_handle__arrow--r{
            position: relative!important;
            right: 2px!important;
        }

        .ic_handle-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            height: 100%;
            transform: translate(-50%, -50%);
            z-index: var(--z-index-handle);

            svg.handle__arrow.handle__arrow--l.feather.feather-chevron-left {
                position: relative;
                left: 2px;
            }
            svg.handle__arrow.handle__arrow--r.feather.feather-chevron-right {
                position: relative;
                right: 2px;
            }
        }
        .ic_handle{
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background: var(--handle-bg);
            border-radius: 50%;
            width: var(--handle-width);
            height: var(--handle-height);
            z-index: var(--z-index-handle)
        }

        .ic_handle-line{
            content: '';
            position: absolute;
            top:0;
            width: var(--handle-line-width);
            height: 100%;
            background: var(--handle-line-bg);
            z-index: var(--z-index-handle-line);
            pointer-events:none;
            user-select:none;
        }

        .ic_compare__range{
            position: absolute;
            cursor: ew-resize;
            left: calc(var(--handle-width) / -2);
            width: calc(100% + var(--handle-width));
            /*width: 100%;*/
            transform: translatey(-50%);
            top: calc(50%);
            z-index: var(--z-index-range-input);
            -webkit-appearance: none;
            height: var(--handle-height);
            /*!* debugging purposes only *!*/
            background: rgba(0,0,0,.4);
            opacity: .0;
        }

        .ic_object-fit-cover{
            object-fit: cover;
        }
    }

     @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        

    }

</style>