<template>
    <md-tab md-label="Premium Clicks and Calls" id="clicks-and-calls">
        <div class="empty-block"></div>

        <div class="md-layout">
            <div class="md-layout-item" :class="{'md-size-100': mobile}">
                <image-compare
                        class="bg-white h-64 w-full"
                        value="50"
                        before="https://rehab-connect.s3.amazonaws.com/assets/claimed.png"
                        after="https://rehab-connect.s3.amazonaws.com/assets/premium-with-clicks-and-calls.png"
                ></image-compare>
            </div>
            <div class="md-layout-item" :class="{'md-size-100': mobile}">
                <div class="explanations">
                    <h4 v-if="!mobile">Replace third-party ads</h4>
                    <p v-if="!mobile">Upgrade your listing and replace third-party ads.</p>

                    <h4 class="second-header">Premium Clicks and Calls</h4>
                    <p>Put new patient connections a click or call away.</p>

<!--                    <h4>Pricing for any facility and budget</h4>-->
<!--                    <p>We are committed to the visibility of all licensed facilities, no matter their size or budget</p>-->

                    <md-button
                            :md-ripple="false"
                            class="md-primary md-raised"
                            @click="$emit('next')"
                    >Continue</md-button>
                   
                </div>
            </div>
        </div>
    </md-tab>
</template>

<script>
    import ImageCompare from "../../ImageCompare";
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: "UnlimitedBedRequestsTab",
        components: {
            ImageCompare
        },
        data() {
            return {
                goal: null,
                funnelData: null,
                mobile: false
            }
        },
        computed: {
            ...mapGetters([
                'goPremiumFunnelInfo',
                'totalSubscriptionCost',
            ]),
        },
        created() {
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
            this.funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
        },
        mounted(){
             this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
        }
    }
</script>

<style lang="scss" scoped>
    .md-tab {
        background-color: #fff;

        .empty-block {
            display:block;
            height: 50px;

        }

        .explanations {
            width: 60%;
            margin-left: 50px;

            h4 {
                margin-top: 0;
                font-size: 16px;
                margin-bottom: 0;
                color: #000;
                font-weight: 500;
            }

            .second-header {
                margin-top: 50px;
            }

            p {
                margin-top: 10px;
                font-size: 16px;
                color: #434343;

            }

            .md-button {
                height: 44px;
                padding-left: 25px;
                padding-right: 25px;
                box-shadow: none;
                color: #3671FF;
                border: 1px solid #3671FF;
                text-transform: none;
                margin-right: 0;
                margin-top: 40px;
                width: 130px;
                float: right;
            }

            .md-primary {
                color: #fff;
                border: none;
                background-color: #0078D3
            }

        }
        
    }
    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        
        .ic_wrapper{
            min-height: 264px!important;
        }
        .md-layout-item{
            margin-left: auto;
            margin-right: auto;
        }
        .md-tab{ 
             .empty-block{
                height: 40px;
            }

            .explanations{
                margin-top: 25px;
                width: 100%;
                margin-left: 5px;
                display: block;
                p{
                    font-size: 16px;
                    line-height: 22px;
                }
                .second-header{
                    margin-top: 0px;
                    padding-top: 6px;
                    display: block;
                    letter-spacing: -0.35px;
                    font-size: 19px;
                }
                .md-button{
                     margin-top: 10px;
                }
            }
        }
        
    }
</style>