<template>



    <div v-if="funnelData">
        <md-app>


                <md-app-toolbar class="md-primary" v-if="!mobile">
                    <router-link :to="{name: 'dashboard.premium'}">
                        <img src="https://rehab-connect.s3.amazonaws.com/assets/logo_home.svg" alt="Rehab.com Logo" class="rehab-logo">
                    </router-link>
                </md-app-toolbar>



            <md-app-content class="overflowhidden">
                <div class="backArrow" v-if="activeTab == 'bed-requests-tab'" @click="activeTab = 'clicks-and-calls'"><md-icon>keyboard_arrow_left</md-icon></div>                
                <router-link v-else :to="'/premium?funnel=keyword-scan'">
                    <div class="backArrow" ><md-icon>keyboard_arrow_left</md-icon></div>
                </router-link>
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item md-large-size-60 md-medium-size-60 md-small-size-60">
                       
                        <template v-if="mobile">
                            <div class="headers">
                                <h1 v-if="activeTab == 'clicks-and-calls'">Get more new patient connections</h1>
                                <h1 v-else>Extend the reach of your program</h1>
                            </div>
                        </template>
                        <template v-else>
                            <div class="headers" v-if="$route.name === 'premium.funnel.locked-in-price'">
                                <h1>Great job! You have locked-in your best price.</h1>
                                <h2>Explore settings to help you get more new patient connections</h2>
                            </div>
                            <div class="headers" v-else>
                                <h1>Increase the visibility of your facility</h1>
                                <h2>Explore settings to help you get more new patient connections</h2>
                            </div>
                        </template>
                        <md-tabs
                                md-alignment="centered"
                                class="choice-tabs"
                                :mdActiveTab="activeTab"
                                @md-changed="tabChanged"
                        >
                            <template v-if="funnelData.cta === 'subscription'">
                                <unlimited-bed-requests-tab
                                        @next="changeTab('clicks-and-calls')"
                                ></unlimited-bed-requests-tab>
                                <premium-clicks-and-calls-tab
                                        @next="$router.push({name: 'premium.funnel.select-goal'})"
                                ></premium-clicks-and-calls-tab>
                            </template>

                            <template v-else>
                                <premium-clicks-and-calls-tab
                                        @next="changeTab('bed-requests-tab')"
                                ></premium-clicks-and-calls-tab>
                                <unlimited-bed-requests-tab
                                        @next="$router.push({name: 'premium.funnel.select-goal'})"
                                ></unlimited-bed-requests-tab>
                            </template>

                        </md-tabs>

                    </div>

                </div>
            </md-app-content>
        </md-app>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import PremiumClicksAndCallsTab from "./PremiumClicksAndCallsTab";
    import UnlimitedBedRequestsTab from "./UnlimitedBedRequestsTab";
    export default {
        name: "PremiumProducts",
        data(){
            return{
                activeTab: null,
                funnelData: null,
                mobile: false,
                interval: null,
                intervalCount: 0,
            }
        },
        components: {
            PremiumClicksAndCallsTab,
            UnlimitedBedRequestsTab
        },
        computed: {
            ...mapGetters([
                'goPremiumFunnelInfo',
            ]),
        },
        created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
        },
        mounted() {

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })

            this.funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
            this.funnelData.currentStep = this.$route.name;
            this.updateGoPremiumFunnelInfo(this.funnelData);

            // MAKE SURE MD-TABS IS RESIZING CORRECTLY.
            this.interval = setInterval(() => {
                window.dispatchEvent(new Event("resize"));
                this.intervalCount++
                if(this.intervalCount >= 20){
                    clearInterval(this.interval)
                }
            }, 100)

        },

        beforeDestroy(){
            clearInterval(this.interval)
        },
        methods: {
            ...mapActions([
                'updateGoPremiumFunnelInfo'
            ]),
            changeTab(tab){
                this.activeTab = null;
                this.$forceUpdate();
                this.activeTab = tab;
            },

            // EVENT EMITTED WHEN THE TAB CHANGES. WE USE IT TO UPDATE OUR activeTab property
            tabChanged(event){
                this.activeTab = event;
            }
        }

    }
</script>

<style lang="scss" scoped>
    .page-container, .md-app, .md-app-container, .md-app-scroller, .md-layout-column, .md-flex, .md-theme-default, .md-scrollbar, .md-content, .md-drawer, .md-theme-default, .md-tabs-content {
        background-color: #ffffff;
    }

    .md-drawer.md-theme-default, .md-app-internal-drawer, .md-content.md-theme-default, .md-app-scroller, body, .md-app:not(.md-overlap).md-theme-default {
        background-color: #ffffff;
    }

    .md-primary{
        border: 1px solid #eee
    }

    .md-toolbar {
        background-color: #FAFAFA !important;
    }

    .rehab-logo {
        height: 32px;
        margin-left: 55px;
    }

    .headers {
        text-align: center;

        h1{
            font-size: 36px;
            font-weight: 300;
            display: block;
            text-align: center;
            padding-top: 0;
            margin-top: 0;
        }

        h2 {
            font-weight: 300;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 35px;
        }

    }


    .md-tabs-navigation {
        .md-button-content{
            text-transform: none !important;
        }

    }
    .choice-tabs {

        .md-tab {
            padding-top: 0px;
            background-color: #fff;

            .empty-block {
                display:block;
                height: 50px;

            }

            .explanations {
                width: 60%;
                margin-left: 50px;

                h4 {
                    font-size: 20px;
                    margin-bottom: 0;
                    color: #00234B;
                    font-weight: 500;
                }
                p {
                    margin-top: 10px;
                    font-size: 16px;
                    color: #434343;

                }

                .md-button {
                    height: 44px;
                    padding-left: 25px;
                    padding-right: 25px;
                    box-shadow: none;
                    color: #3671FF;
                    border: 1px solid #3671FF;
                    text-transform: none;
                    margin-left: 0;
                    margin-top: 30px;
                    width: 130px;
                }

                .md-primary {
                    color: #fff;
                    border: none;
                    background-color: #0078D3
                }

            }
        }
        
    }
    .md-layout-item.md-large-size-60{
        max-width: 940px;
        min-width: 940px;
    }

    .overflowhidden{
        overflow: hidden;
    }

    .overflowhidden .backArrow{
   
    font-weight: 600;
    font-size: 20px;
    position: absolute;
    top: 360px;
    left: 120px;
    cursor: pointer;
    .md-icon {
        color: #0078D3;
    }
}

@media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {

    
    .md-layout-item.md-large-size-60{
        min-width:unset!important;
        width: 100%;
        min-height: 222px;
    }
    .headers h1{
        font-size: 25px;
        line-height: 30px;
        padding: 0px;
        margin-top: 10px;
        margin-bottom: 0;
        letter-spacing: -1px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .headers h2{
        font-size: 18px;
        margin-bottom: 10px;
    }

    .md-app-content{
        padding: 15px;
        padding-top: 20px;
    }

    .md-tabs.md-theme-default .md-tabs-navigation .md-button{
        font-size: 14px!important;
    }

    .overflowhidden{
        height: 99.9vh;
        overflow: hidden;
    }
}

</style>